import { css } from '@emotion/react'
import { Record } from 'datocms-structured-text-utils'
import {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useId,
  useState,
} from 'react'

import { useElementHeight } from '../hooks/useElementRect'
import { absoluteFill, inputWidth } from '../theme/mixins'
import { toSlug } from '../utils'
import { IFieldStyles } from './Form'

export interface IMultilineTextField extends Record {
  __typename: 'DatoCmsMultilineTextField'
  label: string
  required: boolean
  width: 'Full' | 'Half'
}

interface FieldProps {
  data: IMultilineTextField
  onChange: (name: string, value: string) => void
  fieldStyles: IFieldStyles
}

const MultilineTextField = ({
  data: { label, required, width },
  onChange,
  fieldStyles,
}: FieldProps): JSX.Element => {
  const name = toSlug(label)

  const [shrink, setShrink] = useState(false)
  const [value, setValue] = useState('')
  const handleFocus = () => {
    if (!shrink) {
      setShrink(true)
    }
  }
  const handleBlur = () => {
    if (value.length > 0) {
      setShrink(true)
    } else {
      setShrink(false)
    }
  }
  useEffect(() => {
    if (value.length > 0) {
      setShrink(true)
    }
  }, [value])

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }, [])

  useEffect(() => {
    onChange(name, value)
  }, [onChange, name, value])

  const uniqueId = useId()

  const [labelRef, setLabelRef] = useState<HTMLElement | null>(null)
  const labelHeight = useElementHeight(labelRef)

  const styles = {
    container: css`
      ${inputWidth(width)}
    `,
    sizer: css`
      display: block;
      visibility: hidden;
      /* min-height: 9em; */
      padding-top: 1.75em;
      padding-bottom: 1.5em;
      min-height: max(9em, 3.25em + ${labelHeight}px);
      transition: min-height 150ms ease;
    `,
    textArea: css`
      ${absoluteFill}
      resize: none;
      height: 100%;
      border: none;
      padding-top: 1.75em;
      padding-bottom: 1.5em;
    `,
  }

  return (
    <div css={[fieldStyles.container, styles.container]}>
      <label
        htmlFor={name + uniqueId}
        css={[
          fieldStyles.label,
          shrink && fieldStyles.shrink,
          required && fieldStyles.required,
        ]}
        ref={node => setLabelRef(node)}
      >
        {label}
      </label>
      <div css={fieldStyles.inputBase}>
        <span css={[fieldStyles.input, styles.sizer]}>
          {value.split(/\n/g).map((text, i) => (
            <Fragment key={i}>
              {text}
              <br />
            </Fragment>
          ))}
        </span>
        <textarea
          css={[fieldStyles.input, styles.textArea]}
          name={name}
          id={name + uniqueId}
          required={required}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
    </div>
  )
}

export default MultilineTextField
