import { css } from '@emotion/react'
import { LogoProps } from '@the-door/common/src/types'

const DoorLogoStacked = ({
  fill = '#fff',
  ...props
}: LogoProps): JSX.Element => {
  const style = css`
    path {
      fill: ${fill};
    }
  `
  return (
    <svg
      aria-label="The Door"
      viewBox="0 0 238 148"
      css={style}
      {...props}
    >
      <path d="M48.1698 19.7899C48.1698 19.7899 50.7098 17.0999 58.0398 18.7399C58.0398 18.7399 60.2798 19.7899 60.2798 24.5699C60.2798 29.3499 59.9798 109.06 59.9798 109.06C59.9798 109.06 60.2798 113.55 53.3998 112.95C46.5198 112.35 27.2298 111.01 25.4398 109.81C25.4398 109.81 24.0898 108.16 47.8698 101.44L49.9598 100.69L49.3598 92.9099C49.3598 92.9099 47.5698 19.4899 48.1598 19.7899H48.1698Z" />
      <path d="M10.1208 16.3102C10.1208 16.3102 42.7108 2.60021 45.6408 0.350208C45.6408 0.350208 52.9908 -1.79979 48.7908 4.51021C48.7908 4.51021 31.3708 13.3902 12.4908 20.6902C12.4908 20.6902 5.52083 21.3602 10.1308 16.3102H10.1208Z" />
      <path d="M30.6895 22.3805L31.0295 47.7805C31.0295 47.7805 33.8295 53.6105 36.6495 47.7805C36.6495 47.7805 37.7695 22.1505 36.6495 21.7005C36.6495 21.7005 33.3995 15.6805 30.6895 22.3705V22.3805Z" />
      <path d="M22.1501 27.21C22.1501 27.21 21.9301 52.5 22.2601 53.28C22.2601 53.28 19.2101 60.57 16.2001 53L16.0801 28.67C16.0801 28.67 17.6601 21.96 22.1501 27.21Z" />
      <path d="M16.6406 66.7706C16.6406 66.7706 16.8606 92.3906 18.2106 96.3306C18.2106 96.3306 21.2706 100.391 23.2706 95.8806C23.2706 95.8806 23.0406 66.8906 22.3706 65.7606C22.3706 65.7606 18.6606 61.2606 16.6406 66.7706Z" />
      <path d="M36.8699 62.7205C36.8699 62.7205 37.6599 89.8005 38.1099 90.3705C38.1099 90.3705 37.0999 95.6605 34.1999 93.9905C33.8599 93.7905 33.5199 93.4805 33.1299 93.1205C32.7299 92.7405 32.7399 91.9505 32.2699 91.3905C32.2699 91.3905 30.1399 67.5605 31.0299 63.2905C31.0299 63.2905 33.1399 57.3905 36.8699 62.7305V62.7205Z" />
      <path d="M37.8996 51.5101C34.6496 53.7201 36.2096 59.4401 40.4996 58.0101C44.6596 56.5801 42.8396 49.5601 37.7696 51.6401" />
      <path d="M9.34913 25.7499C9.34913 25.7499 8.85913 81.9699 11.7791 101.89C11.7791 101.89 12.9891 105.41 9.58913 105.78C9.58913 105.78 6.06913 107.24 6.30913 99.0999C6.30913 99.0999 3.75913 43.1199 4.84913 26.6099C4.84913 26.6099 6.66913 18.9599 9.33913 25.7599L9.34913 25.7499Z" />
      <path d="M73.3402 141.59L71.4002 141.35V131.27C71.4002 131.27 72.2502 127.63 66.9102 128.48C66.9102 128.48 59.0202 128.6 58.4102 128.12L58.0502 121.56C58.0502 121.56 58.2902 119.01 54.4102 119.37C54.4102 119.37 45.6702 119.49 45.1802 118.64L44.9402 112.2C44.9402 112.2 46.2702 109.53 40.3302 109.65L27.5802 108.8L49.6902 103.34C49.6902 103.34 50.9602 102.98 51.7902 102.15C52.7202 101.22 53.3802 100.36 53.3802 96.9102C53.3802 96.9102 50.1702 42.7002 50.7902 28.0802L50.8202 9.01015C50.8202 9.01015 48.0402 4.90015 46.1002 10.4702C46.1002 10.4702 46.2902 48.4001 47.0702 76.8401C47.0702 76.8401 47.8102 88.9301 47.8102 95.2001C47.8102 98.4901 46.6102 99.1402 45.0402 99.6502L8.7502 108.94C8.7502 108.94 4.6202 112.46 10.5702 112.7C10.5702 112.7 14.7002 111.61 15.1802 112.09C15.1802 112.09 38.3802 113.79 39.9502 114.4C39.9502 114.4 40.8002 116.59 40.5602 118.29L40.4402 118.9C40.4402 118.9 33.7602 117.81 30.7302 118.05C30.7302 118.05 26.9702 121.09 31.5802 122.79L53.4402 124L53.8002 128.25L44.4502 128.13C44.4502 128.13 40.5602 130.8 45.0602 132.74L66.6702 132.98V141.36L38.7402 141.24L38.0102 128.49C38.0102 128.49 37.8902 126.42 34.3702 127.16L25.3802 129.22L24.0502 120.48C24.0502 120.48 24.0502 116.71 19.3102 119.02L12.5102 122.18L10.4502 115.99C10.4502 115.99 5.9602 113.56 6.9302 118.78L8.6302 125.94C8.6302 125.94 8.8702 128.98 12.8802 127.16C16.8902 125.34 19.8002 123.88 19.8002 123.88L21.0102 132.02C21.0102 132.02 20.2802 134.93 24.2902 134.08C28.3002 133.23 33.5202 132.14 33.5202 132.14L34.0102 141.25L12.8202 141.75C12.8202 141.75 10.8302 142.73 10.7102 144.03C10.6202 144.97 11.0802 145.4 12.4502 146.12C12.4502 146.12 71.4102 145.62 72.0202 145.86C72.0202 145.86 74.2102 146.1 74.6902 144.77C74.6902 144.77 76.2702 142.82 73.3602 141.61L73.3402 141.59Z" />
      <path d="M0 116.33L4.37 142.19C4.37 142.19 7.53 147.29 8.74 141.22L3.76 115.11C3.76 115.11 1.21 111.71 0 116.32V116.33Z" />
      <path d="M105.09 15.5102H100.17V53.3602H95.36V15.5102H90.5V10.7002H105.09V15.5102Z" />
      <path d="M123.54 53.36H118.73V34.14H111.73V53.36H106.92V10.71H111.73V29.34H118.73V10.71H123.54V53.36Z" />
      <path d="M140.8 53.36H126.68V10.71H140.8V15.52H131.49V29.05H136.89V33.85H131.49V48.56H140.8V53.36Z" />
      <path d="M124.87 130.07C124.87 132.28 124.45 134.35 123.61 136.29C122.77 138.23 121.63 139.93 120.18 141.37C118.73 142.82 117.04 143.96 115.1 144.8C113.16 145.64 111.08 146.06 108.88 146.06H92.9004V63.96H108.88C111.09 63.96 113.16 64.38 115.1 65.22C117.04 66.06 118.73 67.2 120.18 68.64C121.63 70.09 122.77 71.78 123.61 73.72C124.45 75.66 124.87 77.74 124.87 79.94V130.06V130.07ZM115.62 130.07V79.95C115.62 78.05 114.95 76.45 113.62 75.16C112.29 73.87 110.71 73.22 108.88 73.22H102.14V136.82H108.88C110.71 136.82 112.29 136.17 113.62 134.88C114.95 133.59 115.62 131.99 115.62 130.08V130.07Z" />
      <path d="M162.431 131.561C162.431 133.771 162.011 135.841 161.171 137.781C160.331 139.721 159.191 141.421 157.741 142.861C156.291 144.311 154.601 145.451 152.661 146.291C150.721 147.131 148.641 147.551 146.441 147.551C144.241 147.551 142.161 147.131 140.221 146.291C138.281 145.451 136.581 144.311 135.141 142.861C133.691 141.411 132.551 139.721 131.721 137.781C130.881 135.841 130.461 133.771 130.461 131.561V78.5806C130.461 76.3706 130.881 74.3006 131.721 72.3606C132.561 70.4206 133.701 68.7306 135.141 67.2806C136.591 65.8306 138.281 64.6906 140.221 63.8606C142.161 63.0206 144.231 62.6006 146.441 62.6006C148.651 62.6006 150.721 63.0206 152.661 63.8606C154.601 64.7006 156.291 65.8406 157.741 67.2806C159.191 68.7306 160.331 70.4206 161.171 72.3606C162.011 74.3006 162.431 76.3806 162.431 78.5806V131.561ZM153.181 131.561V78.5806C153.181 76.6806 152.511 75.0806 151.181 73.7906C149.851 72.5006 148.271 71.8506 146.441 71.8506C144.611 71.8506 142.941 72.5006 141.651 73.7906C140.351 75.0806 139.711 76.6806 139.711 78.5806V131.561C139.711 133.461 140.361 135.061 141.651 136.361C142.941 137.651 144.541 138.301 146.441 138.301C148.341 138.301 149.851 137.651 151.181 136.361C152.511 135.071 153.181 133.471 153.181 131.561Z" />
      <path d="M199.999 131.561C199.999 133.771 199.579 135.841 198.739 137.781C197.899 139.721 196.759 141.421 195.309 142.861C193.859 144.311 192.169 145.451 190.229 146.291C188.289 147.131 186.209 147.551 184.009 147.551C181.809 147.551 179.729 147.131 177.789 146.291C175.849 145.451 174.149 144.311 172.709 142.861C171.259 141.411 170.119 139.721 169.289 137.781C168.449 135.841 168.029 133.771 168.029 131.561V78.5806C168.029 76.3706 168.449 74.3006 169.289 72.3606C170.129 70.4206 171.269 68.7306 172.709 67.2806C174.159 65.8306 175.849 64.6906 177.789 63.8606C179.729 63.0206 181.799 62.6006 184.009 62.6006C186.219 62.6006 188.289 63.0206 190.229 63.8606C192.169 64.7006 193.859 65.8406 195.309 67.2806C196.759 68.7306 197.899 70.4206 198.739 72.3606C199.579 74.3006 199.999 76.3806 199.999 78.5806V131.561ZM190.749 131.561V78.5806C190.749 76.6806 190.079 75.0806 188.749 73.7906C187.419 72.5006 185.839 71.8506 184.009 71.8506C182.179 71.8506 180.509 72.5006 179.219 73.7906C177.919 75.0806 177.279 76.6806 177.279 78.5806V131.561C177.279 133.461 177.929 135.061 179.219 136.361C180.509 137.651 182.109 138.301 184.009 138.301C185.909 138.301 187.419 137.651 188.749 136.361C190.079 135.071 190.749 133.471 190.749 131.561Z" />
      <path d="M237.79 146.06H228.54V117.06C228.54 114.7 227.72 112.63 226.09 110.84C224.45 109.05 222.57 108.16 220.44 108.16H215.07V146.07H205.82V63.96H221.81C223.87 63.96 225.81 64.44 227.63 65.39C229.46 66.34 231.09 67.58 232.54 69.1C233.98 70.62 235.13 72.37 235.96 74.35C236.8 76.33 237.22 78.35 237.22 80.4V91.7C237.22 94.36 236.54 96.71 235.17 98.72C233.8 100.74 232.01 102.13 229.8 102.89C232.01 103.88 233.89 105.5 235.45 107.74C237.01 109.99 237.79 112.71 237.79 115.9V146.04V146.06ZM227.97 90.68V82C227.97 79.49 227.27 77.4 225.86 75.72C224.45 74.05 222.45 73.21 219.86 73.21H215.07V99.59H219.86C221.99 99.59 223.87 98.7 225.51 96.91C227.15 95.12 227.96 93.05 227.96 90.69L227.97 90.68Z" />
    </svg>
  )
}

export default DoorLogoStacked
