export const colors = {
  navy: '#0d3980',
  navyDark: '#031b4e',
  blueLight: '#75BDFF',
  blue: '#4497E3',
  blueMid: '#2578C6',
  blueDark: '#0455a0',
  pinkLight: '#ff5386',
  pink: '#FD2F6E',
  pinkDark: '#9a0c36',
  tealLight: '#2dcce5',
  teal: '#00A4BC',
  tealDark: '#00424b',
  greenLight: '#dbf039',
  green: '#b1c517',
  greenDark: '#758d0b',
  yellowLight: '#ffc44d',
  yellow: '#FDAF17',
  yellowDark: '#db8300',
  purpleLight: '#7A63C7',
  purple: '#604AA8',
  purpleDark: '#32245e',
  gray95: '#EEF4FA',
  gray92: '#DEEAF7',
  gray85: '#d2d9e0',
  gray75: '#b6bfc9',
  gray66: '#9aa8b6',
  gray50: '#697f96',
  gray40: '#576575',
}
