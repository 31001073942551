import { css } from '@emotion/react'
import { LogoProps } from '@the-door/common/src/types'

const DoorLogo = ({
  fill = '#fff',
  ...props
}: LogoProps): JSX.Element => {
  const style = css`
    path {
      fill: ${fill};
    }
  `
  return (
    <svg
      aria-label="The Door"
      viewBox="0 0 155 49"
      css={style}
      {...props}
    >
      <path d="M40.7825 42.2357H46.6373V11.2973H50.8979V5.76421H36.5219V11.2973H40.7825V42.2357Z" />
      <path d="M67.1519 42.2357V5.76421H61.2429V21.535H58.0004V5.76421H52.0914V42.2357H58.0004V27.2289H61.2429V42.2357H67.1519Z" />
      <path d="M81.9244 11.0238V5.76421H69.5931V42.2357H81.9244V36.9761H75.5063V25.9703H79.7669V20.9881H75.5063V11.0238H81.9244Z" />
      <path d="M103.349 35.3355V12.8856C103.349 7.79085 100.052 5.76823 96.4716 5.76823H88.6847V42.2357H96.4716C100.052 42.2357 103.349 40.4302 103.349 35.3355ZM94.5979 37.0324V11.0238H95.9625C96.9848 11.0238 97.4981 11.627 97.4981 12.8856V35.1183C97.4981 36.4332 96.989 37.0364 95.9625 37.0364H94.5979V37.0324Z" />
      <path d="M120.283 35.444V12.5559C120.283 8.17688 116.986 5.21734 112.897 5.21734C108.807 5.21734 105.51 8.17286 105.51 12.5559V35.444C105.51 39.823 108.807 42.7826 112.897 42.7826C116.986 42.7826 120.283 39.827 120.283 35.444ZM111.474 35.826V12.1698C111.474 11.2369 112.041 10.6901 112.892 10.6901C113.744 10.6901 114.311 11.2369 114.311 12.1698V35.826C114.311 36.7589 113.744 37.3058 112.892 37.3058C112.041 37.3058 111.474 36.7589 111.474 35.826Z" />
      <path d="M137.159 35.444V12.5559C137.159 8.17688 133.862 5.21734 129.772 5.21734C125.683 5.21734 122.386 8.17286 122.386 12.5559V35.444C122.386 39.823 125.683 42.7826 129.772 42.7826C133.862 42.7826 137.159 39.827 137.159 35.444ZM128.349 35.826V12.1698C128.349 11.2369 128.917 10.6901 129.768 10.6901C130.619 10.6901 131.187 11.2369 131.187 12.1698V35.826C131.187 36.7589 130.619 37.3058 129.768 37.3058C128.917 37.3058 128.349 36.7589 128.349 35.826Z" />
      <path d="M153.525 20.4935V12.1698C153.525 7.40482 150.287 5.76421 147.387 5.76421H139.429V42.2357H145.338V27.0118H146.702C147.725 27.0118 148.008 27.6149 148.008 28.6564V36.5981C148.008 39.3365 148.292 40.8162 148.806 42.2397H154.431C154.034 40.4865 153.692 38.4599 153.692 36.1598V28.769C153.692 26.9635 153.066 25.5923 151.53 24.6071C153.066 23.622 153.521 22.1985 153.521 20.5016L153.525 20.4935ZM147.842 20.6021C147.842 21.7521 147.558 22.1904 146.761 22.1904H145.342V10.9112H146.706C147.504 10.9112 147.842 11.4018 147.842 12.4996V20.6021V20.6021Z" />
      <path d="M16.061 6.50185C16.061 6.50185 16.9079 5.61807 19.3519 6.15688C19.3519 6.15688 20.0988 6.50185 20.0988 8.07228C20.0988 9.64271 19.9987 35.8308 19.9987 35.8308C19.9987 35.8308 20.0988 37.306 17.8048 37.1088C15.5109 36.9117 9.07911 36.4715 8.48228 36.0772C8.48228 36.0772 8.03216 35.5351 15.961 33.3273L16.6578 33.0809L16.4578 30.5248C16.4578 30.5248 15.861 6.40329 16.0577 6.50185H16.061Z" />
      <path d="M3.37424 5.35852C3.37424 5.35852 14.2405 0.85421 15.2175 0.114991C15.2175 0.114991 17.6681 -0.591375 16.2677 1.48173C16.2677 1.48173 10.4595 4.39918 4.16445 6.79754C4.16445 6.79754 1.84049 7.01766 3.37757 5.35852H3.37424Z" />
      <path d="M10.2328 7.35277L10.3462 15.6977C10.3462 15.6977 11.2797 17.6131 12.22 15.6977C12.22 15.6977 12.5934 7.27721 12.22 7.12936C12.22 7.12936 11.1364 5.15154 10.2328 7.34949V7.35277Z" />
      <path d="M7.38533 8.93963C7.38533 8.93963 7.31198 17.2485 7.42201 17.5047C7.42201 17.5047 6.40507 19.8998 5.40146 17.4127L5.36145 9.4193C5.36145 9.4193 5.88826 7.21479 7.38533 8.93963Z" />
      <path d="M5.54816 21.9368C5.54816 21.9368 5.62151 30.354 6.07163 31.6485C6.07163 31.6485 7.09191 32.9823 7.75876 31.5006C7.75876 31.5006 7.68207 21.9762 7.45868 21.6049C7.45868 21.6049 6.22167 20.1265 5.54816 21.9368Z" />
      <path d="M12.2934 20.6062C12.2934 20.6062 12.5568 29.5031 12.7068 29.6904C12.7068 29.6904 12.3701 31.4283 11.4031 30.8797C11.2898 30.814 11.1764 30.7121 11.0464 30.5938C10.913 30.469 10.9163 30.2094 10.7596 30.0255C10.7596 30.0255 10.0494 22.1963 10.3462 20.7934C10.3462 20.7934 11.0497 18.855 12.2934 20.6094V20.6062Z" />
      <path d="M12.6368 16.9232C11.5531 17.6493 12.0733 19.5285 13.5037 19.0587C14.8907 18.5889 14.2839 16.2825 12.5934 16.9659" />
      <path d="M3.1175 8.45996C3.1175 8.45996 2.95412 26.9306 3.92772 33.4752C3.92772 33.4752 4.33117 34.6316 3.19752 34.7532C3.19752 34.7532 2.02387 35.2329 2.10389 32.5585C2.10389 32.5585 1.25366 14.1667 1.61709 8.74251C1.61709 8.74251 2.22393 6.22916 3.11417 8.46324L3.1175 8.45996Z" />
      <path d="M24.4533 46.5183L23.8065 46.4394V43.1277C23.8065 43.1277 24.0899 41.9318 22.3094 42.2111C22.3094 42.2111 19.6787 42.2505 19.4753 42.0928L19.3553 39.9376C19.3553 39.9376 19.4353 39.0998 18.1416 39.2181C18.1416 39.2181 15.2275 39.2575 15.0641 38.9782L14.9841 36.8624C14.9841 36.8624 15.4276 35.9852 13.447 36.0246L9.19586 35.7454L16.5679 33.9515C16.5679 33.9515 16.9913 33.8333 17.2681 33.5606C17.5781 33.255 17.7982 32.9725 17.7982 31.839C17.7982 31.839 16.7279 14.0287 16.9346 9.22546L16.9446 2.96017C16.9446 2.96017 16.0177 1.60986 15.3709 3.43984C15.3709 3.43984 15.4342 15.9014 15.6943 25.2452C15.6943 25.2452 15.941 29.2172 15.941 31.2772C15.941 32.3581 15.5409 32.5717 15.0174 32.7392L2.91748 35.7914C2.91748 35.7914 1.54044 36.9478 3.52431 37.0267C3.52431 37.0267 4.90136 36.6686 5.0614 36.8263C5.0614 36.8263 12.7968 37.3848 13.3203 37.5852C13.3203 37.5852 13.6037 38.3047 13.5237 38.8632L13.4837 39.0637C13.4837 39.0637 11.2564 38.7055 10.2461 38.7844C10.2461 38.7844 8.99247 39.7832 10.5296 40.3417L17.8182 40.7392L17.9382 42.1355L14.8207 42.0961C14.8207 42.0961 13.5237 42.9733 15.0241 43.6107L22.2294 43.6895V46.4427L12.9169 46.4033L12.6735 42.2144C12.6735 42.2144 12.6335 41.5343 11.4598 41.7774L8.46232 42.4542L8.01887 39.5828C8.01887 39.5828 8.01887 38.3441 6.43844 39.1031L4.17116 40.1413L3.4843 38.1076C3.4843 38.1076 1.98723 37.3092 2.31065 39.0242L2.87747 41.3766C2.87747 41.3766 2.95749 42.3754 4.29452 41.7774C5.63155 41.1795 6.60182 40.6998 6.60182 40.6998L7.00526 43.3741C7.00526 43.3741 6.76186 44.3302 8.09889 44.0509C9.43592 43.7717 11.1764 43.4136 11.1764 43.4136L11.3398 46.4066L4.27452 46.5708C4.27452 46.5708 3.611 46.8928 3.57099 47.3199C3.54098 47.6288 3.69436 47.77 4.15115 48.0066C4.15115 48.0066 23.8098 47.8423 24.0132 47.9212C24.0132 47.9212 24.7434 48 24.9035 47.563C24.9035 47.563 25.4303 46.9224 24.46 46.5249L24.4533 46.5183Z" />
      <path d="M0 38.2193L1.45706 46.7154C1.45706 46.7154 2.51068 48.391 2.91413 46.3967L1.25367 37.8185C1.25367 37.8185 0.403443 36.7014 0 38.216V38.2193Z" />
    </svg>
  )
}

export default DoorLogo
